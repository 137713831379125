import React from "react";
import { Col } from "react-bootstrap";
import { useQuery } from "../../../hooks";
import okIcon from "../../../assets/icons/receipt ok.svg";
import nokIcon from "../../../assets/icons/receipt nok.svg";
import { Link } from "react-router-dom";
import { MdLiveTv, MdCastForEducation } from "react-icons/md";


export default function Receipt() {
  const { status, refId } = useQuery();
  const isOk = status === "OK";
  return (
    <div className="py-5 row">
      <Col xs="12" md="10" lg="8">
        <div className="wrapper d-flex flex-column flex-center text-center gap-3 p-3">
          {isOk ? (
            <React.Fragment>
              <img
                width="75"
                className="d-block mx-auto"
                src={okIcon}
                alt="status-ok"
              />
              <h4 className="text-primary">از خرید شما سپاسگزاریم</h4>
              <p className="text-success">
                <i className={`bi bi-ticket-detailed ms-1`} />
                شماره سفارش : {refId}
              </p>
              <div className="w-100 d-flex flex-column justify-content-start">
                <div className="d-flex align-items-start justify-content-start my-2">
                  <span style={{ width: "30px", height: "30px" }} className="mx-2 rounded-circle border border-primary d-flex flex-center p-1"><i className="bi bi-book text-primary fs-18"></i></span>
                  <p className="text-start">در صورت خرید کتاب فیزیکی تا 20 روز آینده کتاب شما به پست ارسال و کدرهگیری پیامک خواهد شد.</p>
                </div>
                {/* <div className="d-flex align-items-start justify-content-start my-2">
                  <span style={{ width: "30px", height: "30px" }} className="mx-2 rounded-circle border border-primary d-flex flex-center p-1"><MdLiveTv size={18} color="#2e51db" /></span>
                  <p className="text-start">در صورت خرید جلسه لایو ترید و یا کتابخوانی در روز جلسه لینک اسکای روم جلسه آنلاین برای شما پیامک خواهد شد.</p>
                </div> */}
                <div className="d-flex align-items-start justify-content-start my-2">
                  <span style={{ width: "30px", height: "30px" }} className="mx-2 rounded-circle border border-primary d-flex flex-center p-1"><MdCastForEducation size={18} color="#2e51db" /></span>
                  <p className="text-start">در صورت خرید دوره های آموزشی  میتوانید جلسات ضبط شده و برگزار شده را با مراجعه به <Link className="text-primary" to="/license">لایسنس های من</Link> در اپلیکیشن اسپات پلیر مشاهده فرمایید و جلسات آنلاین در <a target="_blank" href="https://t.me/+VwVp3JMa52pjMWEx" className="fw-bold text-primary">کانال تلگرام</a> در روزهای برگزاری اطلاع رسانی می شود.</p>
                </div>
                {/* <div className="d-flex align-items-lg-center align-items-start justify-content-start my-2">
                  <span className="mx-2 d-flex flex-center p-1"><i className="bi bi-circle text-success fs-35"></i></span>
                  <p className="text-start text-success">در صورت خرید دوره آموزشی اقتصاد 2 اواسط دی ماه دوره برگزار خواهد شد و از طریق پیامک اطلاع رسانی خواهد گردید.</p>
                </div> */}
              </div>

            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                width="100"
                className="d-block mx-auto"
                src={nokIcon}
                alt="status-ok"
              />
              <h4 className="text-danger">متاسفانه پرداخت شما ناموفق بود!</h4>
              <p className="text-warning">
                <i className="bi bi-exclamation-triangle-fill ms-1" />
                در صورت کسروجه و عدم بازگشت وجه به حساب شما تا 3 ساعت آینده به پشتیبانی آکادمی در تلگرام مراجعه و شماره تلفن و نام و نام خانوادگی خود را برای پیگیری ارسال بفرمایید.
              </p>
            </React.Fragment>
          )}
        </div>
      </Col>
    </div>
  );
}
