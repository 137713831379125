import React, { useContext, useEffect, useState } from 'react'
import { toast } from '../../../methods';
import { Button, Form, Input, Modal } from '../../../components';
import { Col } from 'react-bootstrap';
import Context from './_context';
import { axios } from '../../../boot'
import logo from '../../../assets/logos/Marvel academy.svg'

const Login = () => {
    const [page, setPage] = useContext(Context);
    const [data, setData] = useState({});
    const formControls = [
        {
            label: "شماره موبایل",
            labelColor: "black",
            textColor : "black",
            state: "phone",
            type: "number",
        },
    ];
    const login = () => {
        const url = "https://api.academymarvel.com/api/v2/eco2/classLogin";
        let phone = String(data?.phone)
        if(phone[0] == "0"){
            phone = phone.slice(1)
        }
        const body = { phone: phone }
        axios.post(url, body).then((data) => {
            const text = "شما مجاز به تماشای کلاس می باشید";
            setPage("class")
            toast({ text });
        });
    };
    return (
        <>
            <Form onSubmit={() => { login() }} className="SIGNIN row py-2 d-flex flex-column align-items-center justify-content-center ">
                <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                    <img style={{ width: "115px", height: "93px" }} className="" src={logo} alt="logo" />
                    <p className=" my-4 col-12 text-primary text-center fs-20 fw-700 my-1">کلاس اقتصاد</p>
                </div>
                {formControls?.map((item) => (
                    <Col key={item.state} xs="12">
                        <Input
                        style={{text : "black"}}
                            className="text-black rounded-4  fs-14 fw-400"
                            {...item}
                            value={data[item.state]}
                            setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                        />
                    </Col>
                ))}
                <Col xs="12">
                    <Button type="submit" className="w-100">
                        ورود
                    </Button>
                </Col>
            </Form>
        </>
    )
}

export default Login