import React, { useEffect, useState } from 'react'
import { convertPhone, jsonToXlsx, scrollToTop } from '../../../methods'
import { AdminInput, Pagination, PrintModal, Table } from '../../../components'
import { axios } from '../../../boot'
import NewInstallment from '../CourseManage/newInstallment'
import {Row , Col } from 'react-bootstrap'
const AstrologyUnauthorizedEconomy = () => {
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const [purchases, setPurchases] = useState([]);
    const [params, setParams] = useState({ page: 1, ...basicParams })
    const [pages, setPages] = useState({});
    const [installment, setInstallment] = useState(null)
    const saveParams = () => {
        const value = JSON.stringify(params);
        sessionStorage.setItem("params", value);
    };
    const getPurchases = () => {
        saveParams();
        const url = "/admins/shop/getAstroUsersWithOverdueInstallments";
        const config = {
            params: {
                sort: "createdAt:-1",
                perPage: 20,
                ...params,
            },
        };
        config.params.phone && (config.params.phone = convertPhone(params.phone))
        axios.get(url, config).then(({ data }) => {
            setPurchases(data?.users);
            setPages(data.pages);
            scrollToTop();
        });
    };
    const closeInstallment = () => {
        setInstallment(null)
    }
    const getInstallment = (id) => {
        const url = "/admins/shop/newinstallments"
        const body = {
            firstPurchase: id
        }
        axios.post(url, body).then(({ data }) => {
            setInstallment(data)
        })
    }
    const downloadXlsx = async () => {
        const url = "/admins/shop/getAstroUsersWithOverdueInstallments";
        const perPage = 100;
        const totalPages = Math.ceil(pages.totalItems / perPage);
        const config = (page) => ({ params: { ...params, perPage, page } });
        const getAllData = await [...Array(totalPages)]
            .map((e, i) => i + 1)
            .map(
                async (page) => await (await axios.get(url, config(page))).data?.users
            );
        const allData = await Promise.all(getAllData);
        const title = "report";
        const json = allData?.flat().map((e) => ({
            "نام و نام خانوادگی": `${e?.userName}`,
            "شماره موبایل": `${convertPhone(e?.phone)}`,

        }));
        jsonToXlsx({ json, title });
    };
    const formControls = [
        {
            label: "شماره تلفن",
            state: "phone",
            type: "number",
            clear: true,
        },

    ];
    useEffect(getPurchases, [params]);
    return (

        <>
            <PrintModal show={installment != null} onHide={() => closeInstallment()} size="xl">
                <NewInstallment data={installment} />
            </PrintModal>
            <div className="d-print-none d-flex justify-content-between align-items-center">
                <button
                    className="bi bi-filetype-xlsx fs-3 text-success"
                    onClick={downloadXlsx}
                />
            </div>
            <Row className="d-print-none">
                {formControls.map((e) => (
                    <Col key={e.state} xs="12" md="6" lg="4">
                        {React.createElement(e.tag ?? AdminInput, {
                            ...e,
                            value: params[e.state],
                            setValue: (val) => {
                                setPurchases([]);
                                setParams((p) => ({ ...p, page: 1, [e.state]: val }));
                            },
                        })}
                    </Col>
                ))}
            </Row>
            <Table className="d-print-none">
                <thead>
                    <tr>
                        <th>نام خریدار</th>
                        <th>شماره تلفن</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {purchases?.map((e, i) => (
                        <tr key={e.userId}  >
                            <td>
                                {e.userName}
                            </td>
                            <td>{convertPhone(e?.phone)}</td>
                            <td onClick={(i) => i.stopPropagation()}><button onClick={() => { setInstallment({}); getInstallment(e?.firstPurchase) }} className="btn btn-outline-primary btn-sm">نمایش اقساط</button></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                totalPages={pages.totalPages}
                activePage={params.page}
                setActivePage={(page) => setParams((p) => ({ ...p, page }))}
            />

        </>
    )
}

export default AstrologyUnauthorizedEconomy