import React, { useState } from 'react'
import UserOrder from './UserOrder'
import UserInstallment from './UserInstallment'
const UserPurchases = () => {
    const [active, setActive] = useState("order")

    const buttonInfo = [
        {
            id: "order",
            label: "آخرین سفارشات",
            state: "order"
        },
        // {
        //     id: "installment",
        //     label: "آخرین اقساط",
        //     state: "installment"
        // },
    ]
    return (
        <div className="w-100  my-4">
            <div className="rounded-4 bg-dark-black">
                <div className="AboutCourses-sort  sort-items-section col-12 d-flex align-items-center justify-content-start col-gap-5 overflow-auto p-3">
                    {buttonInfo.map((item) => (

                        <button
                            key={item.id}
                            onClick={() => setActive(item.id)}
                            className={`position-relative white-space-nowrap px-1 py-2 border-0 transition ${active === item.state ? "active text-primary" : "text-white"
                                }`}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>
            </div>
            {active == "order" && <UserOrder/>}
            {/* {active == "installment" && <UserInstallment/>} */}
        </div>
    )
}

export default UserPurchases