import React from 'react'
import { moment, axios } from '../../boot'
import { installmentStatus } from '../../constants';
import { Col, Row } from 'react-bootstrap';


const InstallmentCard = ({
    myInstallments = [],
    index = 0,
    items = [],
    amount,
    dueDate,
    status,
    installmentNumber,
    _id
}) => {
    const showStatus = (id = 0) => {
        const { color, name } = installmentStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    const data = [
        // {
        //     label: "نام دوره",
        //     value: items?.map((e) => e.course?.title).join(" - ")
        // },
        {
            label: "شماره قسط",
            value:  installmentNumber+1
        },
        {
            label: "مبلغ قابل پرداخت",
            value: `${amount?.toLocaleString()} تومان`
        },
        // {
        //     label: "تتر",
        //     value: totalPriceUsd
        // },
        {
            label: "تاریخ سررسید",
            value: moment.miladiToShamsi({ date: dueDate })
        },
        {
            label: "وضعیت",
            value: showStatus(status)
        },
    ]
    // const verifyPayment = (_id) => {
    //     const url = "/purchases/newpayinstallment"
    //     const body = {
    //       _id
    //     }
    //     axios.post(url, body).then(({ data }) => {
    //       const link = data.url;
    //       window.location.href = link;
    //     })
    //   }
    const handleInstallments = (index) => {
        if (index === 0) return false
        if (myInstallments[index - 1]?.status == "paid") return false
        return true
    }
    return (
        <div className="w-100 rounded py-2 px-2 bg-jet my-4">
            {data?.map((e, index) =>
                <Row key={`installment-card-${index}`} className='my-2 p-2'>
                    <Col xs="6" className='text-white'>{e.label}</Col>
                    <Col xs="6" className='text-white text-end'>{e.value}</Col>
                </Row>
            )}
            {/* <div className="w-100">
                <button  onClick={()=>verifyPayment(_id)} className="btn btn-primary w-100 my-2 text-white">پرداخت</button>
            </div> */}
        </div>
    )
}

export default InstallmentCard