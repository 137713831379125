import React, { useState } from 'react'
import Context from './_context'
import { Button, Col } from 'react-bootstrap'
import Login from './Login'
import './index.scss'
import Class from './Class'
import { BiLogOutCircle } from "react-icons/bi";

const AstroClass = () => {
    const [page, setPage] = useState("signIn")
    return (
        <div className="ASTROClass row py-4 px-2">
            {page == "class" &&
                <div className="logoutButton flex-center">
                    <button onClick={()=>setPage("signIn")} variant='white' className=""><BiLogOutCircle color='#e6332a' /></button>
                </div>
            }
            <Col className='rounded p-4 mt-4 bg-white' xs="12" lg={page == "signIn" ? "4" : "10"} >
                <Context.Provider value={[page, setPage]}>
                    {page === "signIn" && <Login />}
                    {page === "class" && <Class />}
                </Context.Provider>
            </Col>

        </div>
    )
}

export default AstroClass