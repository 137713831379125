import React from 'react'
import { Col } from 'react-bootstrap'
import './AstroClassOnline.scss'
import { PiScreencastLight } from "react-icons/pi";

const Class = () => {
    return (
        <div className="Row w-100 AstroClassOnline">
            <p className='text-center text-primary fw-700 fs-24 mb-4'> <PiScreencastLight className='fw-700' fontSize={28}/> کلاس آنلاین ستاره شناسی</p>
            <Col className='bg-black' xs="12" lg="12">
                <div className="h_iframe-aparat_embed_frame">
                    <span style={{ display: "block" }}></span>
                    <iframe scrolling="no"
                        allowFullScreen="true"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        src="https://www.aparat.com/embed/live/academymarvel"></iframe>
                </div>
            </Col>
        </div>
    )
}

export default Class